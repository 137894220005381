import { DialogComponentProps } from "@components/tables/Table/Table";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
import { DialogProps } from "@utils/dialogProps/DialogProps";
import { getLocalStorageCompany } from "@utils/localStorage/company";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INastixCurrencyMap } from '@utils/maps/maps'
import CompanyActions from "@actions/CompanyActions/companyActions";
import { ExtracurricularActivitiyType } from "@components/forms/SchoolForm";
import { School } from "@utils/interfaces/interfaces";
import { TableActionButon } from "@styles/Buttons";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import SchoolActions from "@actions/CRUDActions/SchoolActions/schoolActions";
import { MessageContext } from "@contexts/MessageContext";


const ForwardSchoolDialog = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props
    const { setMessage } = useContext(MessageContext)
    const { t } = useTranslation('school_form')

    const company = getLocalStorageCompany()

    const [school, setSchool] = useState<{ [key: string]: string }>()

    const [yearly_fee, setYearlyFee] = useState('')
    const [termly_fee, setTermlyFee] = useState('')
    const [monthly_fee, setMonthlyFee] = useState('')

    const [allow_yearly, setAllowYearly] = useState(false)
    const [allow_termly, setAllowTermly] = useState(false)
    const [allow_monthly, setAllowMonthly] = useState(false)

    const [is_sponsored_yearly, setIsSponsoredYearly] = useState(false)
    const [is_sponsored_termly, setIsSponsoredTermly] = useState(false)
    const [is_sponsored_monthly, setIsSponsoredMonthly] = useState(false)

    const [yearly_sponsored_fee, setYearlySponsoredFee] = useState('')
    const [termly_sponsored_fee, setTermlySponsoredFee] = useState('')
    const [monthly_sponsored_fee, setMonthlySponsoredFee] = useState('')

    const [fees, setFees] = useState<Number[]>([0.00])

    const [num_of_terms, setNumOfTerms] = useState<number>()

    const currency_symbol = INastixCurrencyMap[company.currency]

    // Function to format a number to two decimal places
    function formatNumberToTwoDecimalPlaces(num: Number): string {
        let converted_num = Number(num).toFixed(2)
        return converted_num;
    }

    useEffect(() => {
        if (ids.length > 0) {
            const id = ids[0]
            const school = state_manager.data.find(obj => obj.id === id)
            setSchool(school)

            setNumOfTerms(school.term_type[0].num_of_terms)

            let company_id = company.company_uuid.toString()
            const company_actions = new CompanyActions()
    
            company_actions.getCompanySettings(company_id)
            .then(data => {
                const finance = data.finance
                setAllowYearly(finance.allows_yearly_fees)
                setAllowTermly(finance.allows_termly_fees)
                setAllowMonthly(finance.allows_monthly_fees)
    
                if (school.extracurricular_activitiy_type.toString() === ExtracurricularActivitiyType.INTRAMURAL) {
                    setFees(finance.intramural_fees.map(formatNumberToTwoDecimalPlaces))
                } else {
                    setFees(finance.extramural_fees.map(formatNumberToTwoDecimalPlaces))
                }
            })
                
        }
    }, [ids])


    const handleFeeChange = (event: SelectChangeEvent) => {
        console.log(event.target.name)
        if (event.target.name === 'yearly_fee')
            setYearlyFee(event.target.value as string)
        else if (event.target.name === 'termly_fee')
            setTermlyFee(event.target.value as string)
        else if (event.target.name === 'monthly_fee')
            setMonthlyFee(event.target.value as string)
    }

    const handleIsSponsoredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'is_sponsored_yearly')
            setIsSponsoredYearly(event.target.checked)
        else if (event.target.name === 'is_sponsored_termly')
            setIsSponsoredTermly(event.target.checked)
        else if (event.target.name === 'is_sponsored_monthly')
            setIsSponsoredMonthly(event.target.checked)
    }

    const handleSponsoredFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'yearly_sponsored_fee')
            setYearlySponsoredFee(event.target.value)
        else if (event.target.name === 'termly_sponsored_fee')
            setTermlySponsoredFee(event.target.value)
        else if (event.target.name === 'monthly_sponsored_fee')
            setMonthlySponsoredFee(event.target.value)
    }


    const handleTransfer = async () => {
        if (school){

            console.log(school)

            const final_school = {
                ...school,
                tuition_fee: yearly_fee,
                termly_tuition_fee: termly_fee,
                monthly_tuition_fee: monthly_fee,
                is_sponsored_yearly: is_sponsored_yearly,
                is_sponsored_termly: is_sponsored_termly,
                is_sponsored_monthly: is_sponsored_monthly,
                yearly_sponsored_fee: yearly_sponsored_fee,
                termly_sponsored_fee: termly_sponsored_fee,
                monthly_sponsored_fee: monthly_sponsored_fee
            }

            console.log(final_school)

            const school_actions = new SchoolActions()
            school_actions.update(final_school, school.id.toString())
            .then(() => {
                handleClose()
                state_manager.setGetAction(undefined, undefined, {is_old: true})
            })
            .catch((error: any) => {
                console.log(error)
                setMessage((props) => ({ ...props, error: error}))
            })
        }
    }


    const handleClose = () => {
        onClose()
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle>{t('update_school_fees')}</INastixDialogTitle>
            <DialogDivider/>
            { allow_yearly && <>
                <FormControlLabel 
                    control={
                        <Checkbox 
                            name='is_sponsored_yearly' 
                            checked={is_sponsored_yearly}
                            onChange={handleIsSponsoredChange}
                        />
                    } 
                    label={t("is_sponsored_yearly")} 
                />
                { !is_sponsored_yearly &&
                    <FormControl fullWidth>
                        <InputLabel id="yearly-fee-select-label">{t('yearly_fee')} ({currency_symbol})</InputLabel>
                        <Select
                            labelId='yearly-fee-select-label'
                            id='yearly-fee-select'
                            name='yearly_fee'
                            value={yearly_fee}
                            label={`${t('yearly_fee')} (${currency_symbol})`}
                            onChange={handleFeeChange}
                            inputProps={{ step: 0.01 }}
                            sx={{marginBottom: 2}}
                            autoComplete='off'
                            required
                        >
                            {fees.map(fee => (
                                <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                            ))}
                        </Select>

                    </FormControl> 
                }
                { is_sponsored_yearly ?
                    <TextField
                        id="yearly-sponsored-fee"
                        label={`${t('yearly_sponsored_fee')} (${currency_symbol})`} 
                        name="yearly_sponsored_fee"
                        variant="outlined"
                        type="number"
                        value={yearly_sponsored_fee}
                        onChange={handleSponsoredFeeChange}
                        inputProps={{ step: 0.01 }}
                        sx={{marginBottom: 2}}
                        required
                        fullWidth
                    />
                : null }
            </> }
            {/* ------------------------------ */}
            { allow_termly && <>
                <FormControlLabel 
                    control={
                        <Checkbox 
                            name='is_sponsored_termly' 
                            checked={is_sponsored_termly}
                            onChange={handleIsSponsoredChange}
                        />
                    } 
                    label={t("is_sponsored_termly")} 
                />
                { !is_sponsored_termly &&
                    <FormControl fullWidth>
                        <InputLabel id="termly-fee-select-label">{t('termly_fee')} ({currency_symbol})</InputLabel>
                        <Select
                            labelId='termly-fee-select-label'
                            id='termly-fee-select'
                            name='termly_fee'
                            value={termly_fee}
                            label={`${t('termly_fee')} (${currency_symbol})`}
                            onChange={handleFeeChange}
                            inputProps={{ step: 0.01 }}
                            sx={{marginBottom: 2}}
                            autoComplete='off'
                            required
                        >
                            {fees.map(fee => (
                                <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                            ))}
                        </Select>
                        { parseFloat(termly_fee) > 0 && num_of_terms &&
                            <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_fee) / num_of_terms).toFixed(2)}</Typography>
                        }
                    </FormControl> 
                }
                { is_sponsored_termly ?
                    <>
                        <TextField 
                            id="termly-sponsored-fee"
                            label={`${t('termly_sponsored_fee')} (${currency_symbol})`} 
                            name="termly_sponsored_fee"
                            variant="outlined"
                            type="number"
                            value={termly_sponsored_fee}
                            onChange={handleSponsoredFeeChange}
                            inputProps={{ step: 0.01 }}
                            sx={{marginBottom: 2}}
                            required
                            fullWidth
                        />
                        { parseFloat(termly_sponsored_fee) > 0 && num_of_terms &&
                            <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_sponsored_fee) / num_of_terms).toFixed(2)}</Typography>
                        }
                        <br/>
                    </>
                : null }
            </> }
            {/* ------------------------------ */}
            { allow_monthly && <>
                <FormControlLabel 
                    control={
                        <Checkbox 
                            name='is_sponsored_monthly' 
                            checked={is_sponsored_monthly}
                            onChange={handleIsSponsoredChange}
                        />
                    } 
                    label={t("is_sponsored_monthly")} 
                />
                { !is_sponsored_monthly &&
                    <FormControl fullWidth>
                        <InputLabel id="monthly-fee-select-label">{t('monthly_fee')} ({currency_symbol})</InputLabel>
                        <Select
                            labelId='monthly-fee-select-label'
                            id='monthly-fee-select'
                            name='monthly_fee'
                            value={monthly_fee}
                            label={`${t('monthly_fee')} (${currency_symbol})`}
                            onChange={handleFeeChange}
                            inputProps={{ step: 0.01 }}
                            sx={{marginBottom: 2}}
                            autoComplete='off'
                            required
                        >
                            {fees.map(fee => (
                                <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                            ))}
                        </Select>
                        { parseFloat(monthly_fee) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_fee) / 10).toFixed(2)}</Typography>}
                    </FormControl> 
                }
                { is_sponsored_monthly ?
                    <>
                        <TextField 
                            id="monthly-sponsored-fee"
                            label={`${t('monthly_sponsored_fee')} (${currency_symbol})`} 
                            name="monthly_sponsored_fee"
                            variant="outlined"
                            type="number"
                            value={monthly_sponsored_fee}
                            onChange={handleSponsoredFeeChange}
                            inputProps={{ step: 0.01 }}
                            sx={{marginBottom: 2}}
                            required
                            fullWidth
                        />
                        { parseFloat(monthly_sponsored_fee) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_sponsored_fee) / 10).toFixed(2)}</Typography>}
                        <br/>
                    </>
                : null }
            </> }
            <DialogButton variant='contained' onClick={handleTransfer}>
                    Forward
            </DialogButton>
        </INastixDialog>
    )
}

const ForwardSchoolDialogHandler = (props: DialogComponentProps) => {
    const { disabled, ids, state_manager } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation('schools')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon/>}>
                <Typography>Forward</Typography>
            </TableActionButon>
            <ForwardSchoolDialog
                ids={ids}
                state_manager={state_manager}
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default ForwardSchoolDialogHandler