import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'
import { TableActionButon } from '@styles/Buttons'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import ClassRegisterReportActions from '@actions/ReportActions/classRegisterReportActions'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import { downloadFile } from '@utils/files/blobs'
import DateRangePickerNastix from '@components/pickers/DateRangeNastix'
import { useMediaQuery } from '@mui/system'
import { Theme } from '@mui/material'


const CreateClassRegisterReportDialog = (props: CommonDialogProps) => {
    // eslint-disable-next-line
	const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('children')

	const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [num_of_dates_to_create, setNumOfDatesToCreate] = useState<number | string>(5)

    const handleCreateClassRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const data = new FormData(event.currentTarget) 
        const form_data_object = Object.fromEntries(data.entries())

        setNumOfRequests(props => props + 1)
    
        const create_class_register_report_actions = new ClassRegisterReportActions()
		
        try {
			const data = {
				company_class_ids: ids,
				...form_data_object
			}

            const response = await create_class_register_report_actions.createClassRegisterReport(data)
            setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Class Register ${current_date}.xlsx`
			downloadFile(response, filename)
		} catch (error) {
			console.error('Error generating Excel:', error)
            setNumOfRequests(props => props - 1)
        }
    }

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle sx={{padding: 0, paddingTop: '0.4rem'}}> Class Register Report </INastixDialogTitle>
			<DialogDivider/>
			<form onSubmit={handleCreateClassRegister}>
				<DateRangePickerNastix
					label_range_1="Start Date"
					label_range_2="End Date"
					name_range_1="start_date"
					name_range_2="end_date"
				/>
	            <Button variant='contained' type="submit" sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
	        </form>
			{/* <TextField
				label="Number of class slots"
				type="number"
				required
				sx={{margin: 'auto', marginTop: '1rem'}}
				value={num_of_dates_to_create}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					if (event.target.value) {
						let new_value = parseInt(event.target.value, 10)
						let parsed_new_value = Math.min(Math.max(new_value, 1), 20)
						setNumOfDatesToCreate(parsed_new_value)
					}
				}}
			/> */}
			{/* <INastixDialogSubTitle sx={{padding: 0, paddingBottom: '0.4rem'}}> class </INastixDialogSubTitle> */}
		</INastixDialog>
    )
}


const CreateClassRegisterReportDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

    const { t } = useTranslation('children')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			Class Register
		</TableActionButon>
		<CreateClassRegisterReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateClassRegisterReportDialogHandler