import { useTranslation } from "react-i18next";
import { DialogForIdsProps } from "../interfaces/interface";
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
import { TableCell, TextField, Typography } from "@mui/material";
import { DialogComponentProps } from "@components/tables/Table/Table";
import { useContext, useEffect, useState } from "react";
import { TableActionButon } from '@styles/Buttons'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import { MessageContext } from "@contexts/MessageContext";
import { Child } from "@utils/interfaces/interfaces";
import ChildActions from "@actions/CRUDActions/ChildActions/childActions";
import WarnAboutInvalidChildren from "../MemberCertificatesDialogHandler/WarnAboutInvalidChildren";


const ForwardChildToNewYearDialog = (props: DialogForIdsProps) => {
    const { ids, state_manager, onClose, open } = props
    const { setMessage } = useContext(MessageContext)
    const { t } = useTranslation('children')


    const [children_ready, setChildrenReady] = useState<Child[]>()
    const [children_not_ready, setChildrenNotReady] = useState<Child[]>()

    useEffect(() => {
        const idsToTransfer = ids.map(id => parseInt(id, 10))
    
        const children_to_transfer = state_manager.data.filter((child: Child) =>
            idsToTransfer.includes(child.id)
        )

        let ready_data: Child[] = []
        let not_ready_data: Child[] = []
        for (let i = 0; i < children_to_transfer.length; i++) {
            if (children_to_transfer[i].not_ready_for_transfer)
                not_ready_data = [...not_ready_data, children_to_transfer[i]]
            else
                ready_data = [...ready_data, children_to_transfer[i]]
        }

        setChildrenReady(ready_data)
        setChildrenNotReady(not_ready_data)
    }, [ids])

    const handleTransfer = async () => {
        if (children_ready) {
            const child_actions = new ChildActions()
            child_actions.forwardChildren(children_ready)
            .then(() => {
                handleClose()
                state_manager.setGetAction(undefined, undefined, {is_old: true, archive: false})
            })
            .catch((error: any) => {
                console.log(error)
                setMessage((props) => ({ ...props, error: error }))
            })
        }
    }
    
    const handleClose = () => {
        onClose()
    }

    const cellStyle = {
        textAlign: 'center', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle>{t("confirm_forward")}</INastixDialogTitle>
            <DialogDivider />
            <Typography>
                {t('are_you_sure_you_want_to_forward', { count: ids.length })} {t(ids.length === 1 ? 'member_to_the_new_year' : 'members_to_the_new_year')}?
            </Typography>
            { children_not_ready &&
                <WarnAboutInvalidChildren
                    alert_message={"Childrens schools have not been forawrded yet"}
                    cell_headings={[<Typography>School forwarded</Typography>]}
                    cell_rows={
                        [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
                    }
                    children={children_not_ready}
                />
            }
            <DialogButton variant='contained' onClick={handleTransfer}>
                {t('forward')}
            </DialogButton>
        </INastixDialog>
    )
}

const ForwardChildToNewYearDialogHandler = (props: DialogComponentProps) => {
    const { disabled, ids, state_manager } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation('children')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon />}>
                <Typography>{t('forward')}</Typography>
            </TableActionButon>
            <ForwardChildToNewYearDialog
                ids={ids}
                state_manager={state_manager}
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default ForwardChildToNewYearDialogHandler