import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { GridColumnVisibilityModel  } from '@mui/x-data-grid'

import { CRUDAction } from '@actions/Actions/actions'
import SchoolForm from '@components/forms/SchoolForm'
import Table, { DialogComponent } from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { companyIsInGroup, permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import SendBulkEmailDialogHandler from '@components/dialogs/SendBulkEmailDialogHandler'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { INastixCurrencyMap } from '@utils/maps/maps'
import { CompanyType } from '@utils/enums/enums'
import { CountryTerm } from '@utils/interfaces/interfaces'


import PrintAsPDF from '@components/utils/Helpers/PrintAsPDF'
import React, {useRef} from 'react'
import { renderConnectedCompanyCell } from '../../UsersTable'
import UpdateFeesDialogHandler from '@components/dialogs/UpdateFeesDialog'
import ForwardSchoolDialogHandler from '@components/dialogs/ForwardSchoolDialog'


export interface TableProps {
	action: CRUDAction
	use_as_reporting?: boolean
	dialogs?: DialogComponent[]
}


const valueAddressGetter = (params: any) => {
	if (params.row.addresses.length) {
		const address = params.row.addresses[0]
		return address[params.field]
	}
	return ""  // should mean that shcool is still being setup
}

const valuePhoneNumberContactsGetter = (params: any) => {
	if (params.row.contacts.length) {
		const contact = params.row.contacts[0]
		const full_phone_number = `+${contact.country_code} ${contact.phone_number}`
		return full_phone_number
	}
	return ""  // should mean that shcool is still being setup
}


const valueTermStructureGetter = (params: any) => {
	if (params.field === "term_structure") {
		const term_types: CountryTerm[] = params.row.term_type
		if (term_types.length)
			return term_types[0].name
		else
			return ""  // should mean that shcool is still being setup
	}
	return ""
}


const valueContactsGetter = (params: any) => {
	if (params.row.contacts.length) {
		const contact = params.row.contacts[0]
		return contact[params.field]
	}
	return ""  // should mean that shcool is still being setup
}

const OldSchoolTable = (props: TableProps) => {
	const {action, use_as_reporting, dialogs} = props
    const { t } = useTranslation('schools')
	const ref = useRef<HTMLDivElement>(null) 

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
	const is_master_franchise_doesnt_act_like_franchise_or_franchisor = !company.acts_like_a_franchise && companyIsInGroup(company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])

	const can_add = !use_as_reporting && !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.CREATE_SCHOOLS)
	

	const currency_symbol = INastixCurrencyMap[company.currency]

	const use_pagination = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE) && !company.acts_like_a_franchise ? true : false
	const filterable = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE) && !company.acts_like_a_franchise ? false : true

	const filter_sort = {
		filterable: filterable,
		sortable: false
	}

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'name', headerName: t('name'), minWidth: 240, flex: 0.3 },
		{ field: 'connected_company_name', headerName: t('connected_franchise'), renderCell: (params: any) => renderConnectedCompanyCell(params, 'connected_company_name'), minWidth: 270, flex: 0.2 },
		{ field: 'school_type', headerName: t('school_type'), ...filter_sort, minWidth: 150, flex: 0.2 },
		{ field: 'term_structure', headerName: t('term_structure'), ...filter_sort, valueGetter: valueTermStructureGetter, minWidth: 220, flex: 0.2 },
		{ field: 'extracurricular_activitiy_type', headerName: t('activity_type'), ...filter_sort, minWidth: 150, flex: 0.2 },
		{ field: 'tuition_fee', headerName: `${t('yearly_fee')} (${currency_symbol})`, ...filter_sort, minWidth: 130, flex: 0.2 },
		{ field: 'termly_fee', headerName: `${t('termly_fee')} (${currency_symbol})`, ...filter_sort, minWidth: 130, flex: 0.2 },
		{ field: 'monthly_fee', headerName: `${t('monthly_fee')} (${currency_symbol})`, ...filter_sort, minWidth: 130, flex: 0.2 },
		{ field: 'registration_fee', headerName: `${t('registration_fee')} (${currency_symbol})`, ...filter_sort, minWidth: 180, flex: 0.2 },
		{ field: 'school_owner_name', headerName: t('school_owner'), ...filter_sort, minWidth: 150 },
		{ field: 'email', headerName: t('email'), ...filter_sort, valueGetter: valueContactsGetter, minWidth: 200 },
		{ field: 'phone_number', headerName: t('phone_number'), ...filter_sort, valueGetter: valuePhoneNumberContactsGetter, minWidth: 180 },
		{ field: 'city', headerName: t('city'), ...filter_sort, valueGetter: valueAddressGetter, minWidth: 150, flex: 0.2 },
		{ field: 'country', headerName: t('country'), valueGetter: valueAddressGetter, ...filter_sort, minWidth: 150, flex: 0.2 },
		// { field: 'per_class_fee', headerName: `${t('class_fee')} (${currency_symbol})`, ...filter_sort, minWidth: 100, flex: 0.2 },
		{ field: 'is_school_invoiced', headerName: t('is_school_invoiced'), ...filter_sort, cellClassName: 'center-aligned-cell', minWidth: 160,
			renderCell: (params: any) => {
				if (params.value === true) 
					return <CheckCircleIcon sx={{ color: '#51b77f' }} />
				else
					return <CancelIcon sx={{ color: '#ed586e' }} />
			},
		},
		{ field: 'date_created', headerName: t('date_created'), minWidth:200, flex:2},
		{ field: 'date_modified', headerName: t('date_modified'), minWidth:200, flex:2},
	]

	const column_visibility_model: GridColumnVisibilityModel = {}

	const is_franchise_or_school = companyIsInGroup(company, [CompanyType.FRANCHISEE, CompanyType.SCHOOL])
	if (is_franchise_or_school) {
		column_visibility_model['connected_company_name'] = false
	}

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'connected_company_name') {
			field = 'connected_company__tenant_name'
		}
		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		if (field === 'connected_company_name') {
			field = 'connected_company__tenant_name'
		}

		return field
	}

	const no_selection_dialogs: Array<DialogComponent> = company.needs_to_update_fees ? [UpdateFeesDialogHandler] : []

	const single_selection_dialogs: Array<DialogComponent> = company.needs_to_update_fees ? [] : [ForwardSchoolDialogHandler]


    return (
		<div style={{ position: 'relative' }}>
            {/* Button container */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <PrintAsPDF ref={ref} filename="school_table.pdf" />
            </div>
			<div ref={ref}> 
        <Table 
			Form={SchoolForm} 
			single_selection_dialogs={single_selection_dialogs}
			// no_selection_dialogs={no_selection_dialogs}
			action={action} 
            filter={{is_old: true}}
			columns={columns}
			column_visibility_model={column_visibility_model}
			paginationFilterParser={use_pagination ? paginationFilterParser : undefined}
			paginationSortParser={use_pagination ? paginationSortParser: undefined}
			use_pagination={use_pagination}
			page_size={25}
			amount_of_rows_selection={[25, 50]}
		/>
		</div>
    </div>
    )
}

export default OldSchoolTable