import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Theme, Typography, useMediaQuery} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import DateRangePickerNastix from '@components/pickers/DateRangeNastix'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { ChildAuthorisationMap } from '@utils/maps/maps'
import { capitalizeFirstLetter } from '@utils/utils/util'

import { handleTShirtReportCreate } from '../handleSend'
import { ReportDocType, TShirtReportType } from '../enum'
import { TShirtReportDisplayMap } from '../maps'
import { SelectReportDocType } from '../components'


const CreateFranchiseTShirtReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('t_shirt_reports')

	const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [t_shirt_report_type, setTShirtReportType] = useState<TShirtReportType>(TShirtReportType.PER_CHILD_REPORT)
	const [report_doc_type, setReportDocType] = useState<ReportDocType>(ReportDocType.EXCEL)
	const [child_status_filter, setChildStatusFilter] = useState('all_child_statuses')

	const handleChildStatusFilterChange = (event: SelectChangeEvent) => {
		setChildStatusFilter(event.target.value as string)
	}

	const setReportDocTypeCallback = (value: ReportDocType) => {
		setReportDocType(value)
	}

	const handleTShirtReportTypeChange = (event: SelectChangeEvent) => {
		setTShirtReportType(event.target.value as TShirtReportType)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('create_t_shirt_report')} </INastixDialogTitle>
			<DialogDivider/>
			<form onSubmit={(event) => handleTShirtReportCreate(event, setNumOfRequests, 'school_ids', ids, t_shirt_report_type, report_doc_type)}>
				<Box sx={{margin: '1rem 0'}}>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">{t('t_shirt_report_type')}</InputLabel>
						<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={t_shirt_report_type}
						label={t('t_shirt_report_type')}
						onChange={handleTShirtReportTypeChange}
						>
							<MenuItem value={TShirtReportType.PER_CHILD_REPORT}>{TShirtReportDisplayMap[TShirtReportType.PER_CHILD_REPORT]}</MenuItem>
							<MenuItem value={TShirtReportType.PER_SCHOOL_REPORT}>{TShirtReportDisplayMap[TShirtReportType.PER_SCHOOL_REPORT]}</MenuItem>
						</Select>
					</FormControl>
					<SelectReportDocType setReportDocTypeCallback={setReportDocTypeCallback}/>
				</Box>
				<FormControl fullWidth sx={{marginBottom: '1rem'}}>
					<InputLabel id="demo-simple-select-child-status-label">Child Status Filter</InputLabel>
					<Select
						labelId="demo-simple-select-child-status-label"
						id="demo-simple-select-child-status"
						label="Child Status Filter"
						name="child_status_filter"
						value={child_status_filter}
						onChange={handleChildStatusFilterChange}
					>
						<MenuItem value={'all_child_statuses'}>{"All"}</MenuItem>
						<MenuItem value={1} sx={{textTransform: 'capitalize'}}>{capitalizeFirstLetter(ChildAuthorisationMap['1'])}</MenuItem>
						<MenuItem value={4} sx={{textTransform: 'capitalize'}}>{capitalizeFirstLetter(ChildAuthorisationMap['4'])}</MenuItem>
						<MenuItem value={3} sx={{textTransform: 'capitalize'}}>{capitalizeFirstLetter(ChildAuthorisationMap['3'])}</MenuItem>
						<MenuItem value={5} sx={{textTransform: 'capitalize'}}>{capitalizeFirstLetter(ChildAuthorisationMap['5'])}</MenuItem>
						<MenuItem value={6} sx={{textTransform: 'capitalize'}}>{capitalizeFirstLetter(ChildAuthorisationMap['6'])}</MenuItem>
					</Select>
				</FormControl>
				<DateRangePickerNastix
					label_range_1={t('start_date')}
					label_range_2={t('end_date')}
					name_range_1={'start_date'}
					name_range_2={'end_date'}
				/>
				<Typography sx={{fontSize: '0.7rem', color: 'grey', width: '70%', margin: '0.6rem 0 0.6rem 0rem'}}> Filters by the start date of the children. i.e. The date the child was first set to the authorised or pending payment status </Typography>
				<Button variant='contained' type="submit" sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        	</form>
        </INastixDialog>
	)
}


const CreateFranchiseTShirtReporDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('t_shirt_reports')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> {t('create_t_shirt_report')}</Typography>
		</TableActionButon>
		<CreateFranchiseTShirtReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateFranchiseTShirtReporDialogHandler