import { useTranslation } from "react-i18next";
import { DialogForIdsProps } from "../interfaces/interface";
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog";
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { DialogComponentProps } from "@components/tables/Table/Table";
import { useContext, useEffect, useState } from "react";
import { TableActionButon } from '@styles/Buttons'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import { MessageContext } from "@contexts/MessageContext";
import { Class } from "@utils/interfaces/interfaces";
import ChildActions from "@actions/CRUDActions/ChildActions/childActions";
import WarnAboutInvalidChildren from "../MemberCertificatesDialogHandler/WarnAboutInvalidChildren";
import ClassActions from "@actions/CRUDActions/ClassActions/classActions";
import { ViewList } from "@mui/icons-material";


const ForwardClassesDialog = (props: DialogForIdsProps) => {
    const { ids, state_manager, onClose, open } = props
    const { setMessage } = useContext(MessageContext)
    const { t } = useTranslation('classes')


    const [classes_ready, setClassesReady] = useState<Class[]>()
    const [classes_not_ready, setClassesNotReady] = useState<Class[]>()

    const cellStyle = {
        textAlign: 'center', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    const cellResendStyle = {
        textAlign: 'left', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    const alert_message = "Classes schools have not been forawrded yet"
    const cell_headings = [<Typography>School forwarded</Typography>]

    const cell_rows = [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]

    useEffect(() => {
        const idsToTransfer = ids.map(id => parseInt(id, 10))
    
        const classes_to_transfer = state_manager.data.filter((data: Class) =>
            idsToTransfer.includes(Number(data.id))
        )

        console.log(classes_to_transfer)

        let ready_data: Class[] = []
        let not_ready_data: Class[] = []
        for (let i = 0; i < classes_to_transfer.length; i++) {
            if (classes_to_transfer[i].not_ready_for_transfer)
                not_ready_data = [...not_ready_data, classes_to_transfer[i]]
            else
                ready_data = [...ready_data, classes_to_transfer[i]]
        }

        setClassesReady(ready_data)
        setClassesNotReady(not_ready_data)
        
    }, [ids])

    const handleTransfer = async () => {
        if (classes_ready) {
            const class_actions = new ClassActions()
            Promise.all(
                classes_ready.map((data) =>
                    class_actions.update({ ...data, is_old: false }, data.id.toString())
                )
            )
            .then(() => {
                handleClose()
                state_manager.setGetAction(undefined, undefined, {is_old: true})
            })
            .catch((error: any) => {
                console.log(error)
                setMessage((props) => ({ ...props, error: error }))
            })
        }
    }
    
    const handleClose = () => {
        onClose()
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle>{t("confirm_forward")}</INastixDialogTitle>
            <DialogDivider />
            <Typography>
                {t('are_you_sure_you_want_to_forward', { count: ids.length })} {t(ids.length === 1 ? 'class_to_the_new_year' : 'classes_to_the_new_year')}?
            </Typography>

            {classes_not_ready && classes_not_ready.length > 0 ? 
                <>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ViewList />}
                        aria-controls="panel1-content"
                        sx={{backgroundColor: alert_message ? '#fdb0a7' : "#fff4e5"}}
                    >
                        <Typography><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{alert_message}</span> <span> ({classes_not_ready.length}) </span></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellResendStyle}>Class</TableCell>
                                    {cell_headings.map((cell_heading, index) => (
                                        <TableCell key={index} sx={cellStyle}>
                                            {cell_heading}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {classes_not_ready.map((company_class, index) => (
                                    <TableRow key={`${company_class.name}-${index}`}>
                                        <TableCell sx={cellResendStyle}>{company_class.name}</TableCell>
                                        {cell_rows ? cell_rows.map(cell_row => cell_row) : <></>}
                                    </TableRow>
                                    )
                                )}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                <br/>
                </>
                : <></>
            }
            <DialogButton variant='contained' onClick={handleTransfer}>
                {t('forward')}
            </DialogButton>
        </INastixDialog>
    )
}

const ForwardClassesDialogHandler = (props: DialogComponentProps) => {
    const { disabled, ids, state_manager } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation('classes')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon />}>
                <Typography>{t('forward')}</Typography>
            </TableActionButon>
            <ForwardClassesDialog
                ids={ids}
                state_manager={state_manager}
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default ForwardClassesDialogHandler