import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import ChildForm from '@components/forms/ChildForm'
import Table, { DialogComponent } from '@components/tables/Table/Table'
import UnarchiveChildDialogHandler from '@components/dialogs/UnarchiveChildDialog'
import '../styles.css'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup, permissionValid } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { UserPermissions } from '@utils/enums/permissions'
import { INASTIX_SHIRT_MAP } from '..'

import { MenuItem, Select, Tooltip, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import AccessTime from '@mui/icons-material/AccessTime'

import log from 'loglevel'
import { GridColumnVisibilityModel } from '@mui/x-data-grid'
import { ChildAuthorisation } from '@utils/interfaces/interfaces'
import ForwardChildToNewYearDialogHandler from '@components/dialogs/ForwardChildToNewYearDialog'

export interface TableProps {
	action: CRUDAction
}

const gender_map: Record<number, string>  = {
	1: 'M',
	2: 'F', 
	3: 'Other'
}

const shirt_map: Record<number, string>  = {
	1: 'Extra small',
	2: 'Small',
	3: 'Medium', 
	4: 'Large',
	5: 'Extra large'
}

export const valueConnectedSchoolGetter = (params: any) => {
	if (params.row.school_name) {
		return params.row.school_name
	}
	return ""
}

export const renderConnectedFranchise = (params: any) => {
	if (params.row.franchise_name) {
		return params.row.franchise_name
	}
	return ""
}

export const childTableValueGetter = (params: any) => {
	if (params.field === 'sex')
		return gender_map[params.value] || ''

	else if (params.field === 'shirt_size')
		return INASTIX_SHIRT_MAP[params.value] || ''

	else if (params.field === 'registered') {
		const datePart = new Date(params.value).toISOString().split('T')[0]
		return datePart
	}

	else if (params.field === 'start_date') {
		let datePart = ""
		if (params.value) {
			datePart = new Date(params.value).toISOString().split('T')[0]
		}
		return datePart
	}
}

const OldChildTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('children')

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

	const is_master_franchise_doesnt_act_like_franchise_or_franchisor = !company.acts_like_a_franchise && companyIsInGroup(company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])

	const can_add = !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.CREATE_CHILDREN)

	const columns = [
		{ field: 'id', headerName: t('id') },
		{ field: 'username', hideable: false, headerName: t('name'), minWidth: 200 },
		{ field: 'franchise_name', headerName: "Franchise", cellClassName: '', minWidth: 270, renderCell: renderConnectedFranchise},
		{ field: 'school_name', cellClassName: '', minWidth: 270, headerName: t('school'), valueGetter: valueConnectedSchoolGetter},
		{ field: 'class_name', cellClassName: '', minWidth: 180, headerName: t('class')},
		{ field: 'authorized', sortable: false, headerName: t('authorised'), cellClassName: 'center-aligned-cell',
			renderCell: (params: any) => {
				if (params.value === 1) 
					return <Tooltip title={"Authorised"}><CheckCircleIcon sx={{ color: '#51b77f' }} /></Tooltip>
				else if (params.value === 2) 
					return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
				else if (params.value === 3) 
					return <Tooltip title={"Unauthorised"}><CancelIcon sx={{ color: '#ed586e' }} /></Tooltip>
				else if (params.value === 4) 
					return <Tooltip title={"Pending payment"}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
				else if (params.value === 5) 
					return <Tooltip title={"On hold"}><AccessTime sx={{ color: 'blue' }} /></Tooltip>
				else if (params.value === 6) 
					return <Tooltip title={"Left"}><DoNotDisturb sx={{ color: '#ed586e' }} /></Tooltip>
			},
		},
		{ field: 'shirt_size', sortable: false, filterable: false, headerName: t('shirt_size'), valueGetter: childTableValueGetter },
		{ field: 'payment_structure', sortable: false, headerName: t('payment_structure'), minWidth: 160, flex: 0.2 },
		{ field: 'registered', sortable: false, filterable: false, headerName: t('registered'), minWidth: 120, flex: 0.2, valueGetter: childTableValueGetter},
		{ field: 'parent_email', sortable: false, headerName: t('parent_email'), minWidth: 200 },
		{ field: 'parent_phone', sortable: false, headerName: t('parent_phone'), minWidth: 200 },
		{ field: 'birthday', sortable: false, filterable: false, headerName: t('birthday'), minWidth: 120, flex: 0.2 },
		{ field: 'age', sortable: false, filterable: false, headerName: t('age') },
		{ field: 'sex', sortable: false, filterable: false, headerName: t('gender'), cellClassName: 'center-aligned-cell', minWidth: 85, valueGetter: childTableValueGetter },
 		{ field: 'allergies', filterable: false, sortable: false, headerName: t('allergies'), minWidth: 180, renderCell: (params: any) => {
			const data = params.row.allergies

			if (!data)
				return ""

			try {
				const menuItems = []
				const array_length = data.length

				if (array_length === 0) 
					return <Typography variant='body2'></Typography>
				else if (array_length === 1)
					return <Typography variant='body2'>{data[0]}</Typography>
				else
					for (let i = 0; i < data.length; i++) {
						const option = data[i]
						menuItems.push(
							<MenuItem key={i} value={option} style={{ cursor: 'auto' }}>
									{option}
							</MenuItem>
						)
					}
					return <Select variant='standard' value={data[0]} style={{ fontSize: '0.9rem' }} fullWidth>{menuItems}</Select>
			}
			catch (error) {
				log.error('An error occurred:', error);
				return <Typography variant='body2'>{t('Error loading data')}</Typography>
			}
			} 
		}, 
		{ field: 'start_date', sortable: false, filterable: false, headerName: t('start_date'), minWidth: 120, flex: 0.2, valueGetter: childTableValueGetter },
		{ field: 'monkeynastix_authorised', sortable: false, filterable: false, headerName: t('monkeynastix_authorised'), cellClassName: 'center-aligned-cell',  minWidth: 200,
			renderCell: (params: any) => {
				if (params.value === 1) 
					return <CheckCircleIcon sx={{ color: '#51b77f' }} />
				else if (params.value === 2) 
					return <Tooltip title={t('pending_authorisation')}><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
				else if (params.value === 3) 
					return <CancelIcon sx={{ color: '#ed586e' }} />
			},
		},
		{ field: 'is_from_minastix', sortable: false, filterable: false, headerName: t('Minastix'), cellClassName: 'center-aligned-cell',
			renderCell: (params: any) => {
				if (params.value) 
					return <CheckCircleIcon sx={{ color: '#51b77f' }} />
				else
					return <CancelIcon sx={{ color: '#ed586e' }} />
			},
		},
		{ field: 'is_in_xero', sortable: false, filterable: false, headerName: t('exists_in_xero'), width: 150, cellClassName: 'center-aligned-cell',
			renderCell: (params: any) => {
				if (params.value) 
					return <CheckCircleIcon sx={{ color: '#51b77f' }} />
				else
					return <CancelIcon sx={{ color: '#ed586e' }} />
			},
		}
	]

	function findClosestMatch(input: string): ChildAuthorisation | undefined {
		// Convert input to lowercase for case-insensitive comparison
		const lowerInput = input.toLowerCase();
	
		// Iterate over the statusMap entries
		let closestMatch: ChildAuthorisation | undefined;
		let minDistance = Number.MAX_SAFE_INTEGER;
		for (const [status, value] of statusMap.entries()) {
			// Calculate Levenshtein distance between input and status
			const distance = levenshteinDistance(lowerInput, status);
			if (distance < minDistance) {
				minDistance = distance;
				closestMatch = value;
			}
		}
	
		return closestMatch;
	}

	// Function to calculate Levenshtein distance between two strings
	function levenshteinDistance(str1: string, str2: string): number {
		const len1 = str1.length;
		const len2 = str2.length;

		const dp: number[][] = [];
		for (let i = 0; i <= len1; i++) {
			dp[i] = [];
			dp[i][0] = i;
		}
		for (let j = 0; j <= len2; j++) {
			dp[0][j] = j;
		}

		for (let i = 1; i <= len1; i++) {
			for (let j = 1; j <= len2; j++) {
				const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
				dp[i][j] = Math.min(
					dp[i - 1][j] + 1, // Deletion
					dp[i][j - 1] + 1, // Insertion
					dp[i - 1][j - 1] + cost // Substitution
				);
			}
		}

		return dp[len1][len2]
	}

	const statusMap: Map<string, ChildAuthorisation> = new Map([
		["authorized", ChildAuthorisation.AUTHORISED],
		["authorised", ChildAuthorisation.AUTHORISED],
		["unauthorized", ChildAuthorisation.UNAUTHORISED],
		["unauthorised", ChildAuthorisation.UNAUTHORISED],
		["pending payment", ChildAuthorisation.AWAITING_PAYMENT],
		["awaiting payment", ChildAuthorisation.AWAITING_PAYMENT],
		["on hold", ChildAuthorisation.ON_HOLD],
		["left", ChildAuthorisation.LEFT],
	])

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'franchise_name') {
			field = 'tenant__real_school__connected_company__tenant_name'
		}
		else if (field === 'school_name') {
			field = 'tenant__tenant_name'
		}
		else if (field === 'authorized') {
			if (!value)
				return {field, value, custom_operator}

			const status_number = findClosestMatch(value)
			if (!status_number)
				return {field, value, custom_operator}

			custom_operator = ''
			value = status_number
			field = 'authorized'
		}
		else if (field === 'parent_email') {
			field = 'preferred_parent_contact__email'
		}
		else if (field === 'parent_phone') {
			field = 'preferred_parent_contact__phone_number'
		}
		else if (field === 'class_name') {
			field = 'company_class__name'
		}
		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		if (field === 'username') {
			field = 'first_name'
		}
		else if (field === 'franchise_name') {
			field = 'tenant__real_school__connected_company__tenant_name'
		}
		else if (field === 'school_name') {
			field = 'tenant__tenant_name'
		}
		return field
	}


	const is_school = companyIsInGroup(company, CompanyType.SCHOOL)

	const column_visibility_model: GridColumnVisibilityModel = {}

	if (is_school)
		column_visibility_model['school_name'] = false
	if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor)
		column_visibility_model['franchise_name'] = false
	if (!company.is_xero_company)
		column_visibility_model['is_in_xero'] = false

	const dialogs: Array<DialogComponent> = company.needs_to_update_fees ? [] : [ForwardChildToNewYearDialogHandler]
	
    return (
        <Table
			action={action}
			columns={columns} 
			dialogs={dialogs}
			use_pagination={true}
			filter={{is_old: true, archive: false}}
			page_size={100}
			paginationFilterParser={paginationFilterParser}
			paginationSortParser={paginationSortParser}
			amount_of_rows_selection={[100]}
		/>
    )
}

export default OldChildTable