import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FormHelperText from '@mui/material/FormHelperText'

import AddCircle from '@mui/icons-material/AddCircle'
import Delete from '@mui/icons-material/Delete'


interface FeesProps {
    initial_fees?: number[]
    fees_key: string
    label: string
    helper_text?: string
    disabled?: boolean
}

const HandleFees = (props: FeesProps) => {
    const {initial_fees, fees_key, label, helper_text, disabled} = props

    const{ t } = useTranslation('finance_terms')

    const [fees, setFees] = useState<(number | string)[]>([''])

    useEffect(() => {
        setFees(initial_fees?.length ? initial_fees : [''])
    }, [initial_fees])

    const handleAddFee = () => {
        setFees([...fees, ''])
    }

    const handleDeleteFee = (index: number) => {
        const updated_fees = [...fees]
        updated_fees.splice(index, 1)
        setFees(updated_fees)
    }

    const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Update the state variable with the new value
        let updated_list = [...fees]
        let index = Number(event.target.name[0])
        updated_list[index] = parseFloat(event.target.value)
        setFees(updated_list)
    }

    return (
        <>
        {fees.map((fee, index) => (
            <div key={index}>
            <br/>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                <br/>
                <TextField
                    type="number"
                    label={label}
                    name={`${index}-${fees_key}`}
                    value={fee}
                    inputProps={{ step: 0.01 }}
                    onChange={handleFeeChange}
                    disabled={disabled}
                />
                {index !== 0 && !disabled ?
                    <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteFee(index)}
                    >
                        <Delete/>
                    </IconButton> : null 
                }
            </Box>
            {index === 0 && <FormHelperText>{helper_text}</FormHelperText>}
            </div>
        ))}
        <br/>
        {!disabled ? 
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircle/>}
                onClick={handleAddFee}
            >
                {t('add_fee')}
            </Button> 
        : null }
        </>
    )
}

export default HandleFees