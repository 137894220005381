import { useTranslation } from 'react-i18next'
import { useMemo} from 'react'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'
import AuthorisationsTabs from './AuthorisationsTabs'


const AuthorisationsPage = () => {

	const { t } = useTranslation('payments')

	return (
		<Pages page_title="Authorisations" permission={UserPermissions.EDIT_CHILDREN}>
			<PageHeadingTypography> {t('authorisations')} </PageHeadingTypography>
            <AuthorisationsTabs/>
		</Pages>
	)
}

export default AuthorisationsPage