import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import { PDFDocument, rgb, StandardFonts, PDFPage  } from 'pdf-lib'
import html2pdf from 'html2pdf.js'
import InastixWord from '@images/inastix/inastix_certificates.png'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import FormGroup from '@mui/material/FormGroup'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Child, Term, Evaluation} from '@utils/interfaces/interfaces'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, DialogFormControl, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { capitalizeFirstLetter } from '@utils/utils/util'
import { MessageContext } from '@contexts/MessageContext'
import EvaluationActions from '@actions/CRUDActions/ClassActions/evaluationActions'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { PRINT_CERTIFICATES_TO_MEMBERS, SEND_CERTIFICATES_TO_MEMBERS } from '@adapters/routes/endpoints'
import { ContentType } from '@adapters/api/enums/enum'
import { SchoolType } from '@utils/enums/enums'
import SelectCountryTermsOnly from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectCountryTermOnly'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import SelectTermIndex from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectTermIndex'
import DatePickerObjectNastix from '@components/pickers/DatePickerObjectNastix'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import { CertificateNames } from './enum'
import ValidateChildrenForPrint from './ValidateChildrenForPrint'
import { cleanChildren, cleanEmailMethodChildren, cleanEvaluationChildren, cleanParticipationChildren, drawOnCertificate, inInvalidTermChildren, updateChildPDFDocs } from './util'
import { ChildrenPDFDocs, TextConfig } from './interfaces'
import { ChildEvaluationKeys } from './types'
import SelectCertificateSendMethod, { CertifcateSendMethodType } from './SelectSendCertificateMethod'

import log from 'loglevel'
import dayjs, { Dayjs } from 'dayjs'
import { CertificateNamesToDisplayMap } from './maps'
import { TextField, Theme, useMediaQuery } from '@mui/material'


export const ADDED_ON_TO_TO_END_TERM_DATE = 25

//comment
// NB!! order is import here
export const EVALUATION_KEYS = [
	'monkeynastix_skill',
	'ball_throw',
	'one_leg_hop_left',
	'jump_and_land',
	'dynamic_balance',
	'static_balance_left',
] as const

// NB!! order is import here
export const COL2_EVALUATION_KEYS = [
    'ball_catch',
    'ball_kick',
    'one_leg_hop_right',
    'sequence',
    'static_balance_right',
    'two_legs_jump'
] as const


const SCHOOL_TYPE_TO_CERTIFICATE_PATHS_MAP: Record<SchoolType, Record<CertificateNames, string | null>> = {
	[SchoolType.MONKEYNASTIX]: {
		[CertificateNames.STANDARD_CERTIFICATE]: 'monkeynastix/files/certificates/mnx_2024_certificate.pdf',
		[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: 'monkeynastix/files/certificates/mnx_2024_perceptual_ma_certificate.pdf'
	},
	[SchoolType.BABYNASTIX]: {
		[CertificateNames.STANDARD_CERTIFICATE]: 'monkeynastix/files/certificates/bnx_2024_certificate.pdf',
		[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: null
	},
	[SchoolType.SUPERNASTIX]: {
		[CertificateNames.STANDARD_CERTIFICATE]: 'monkeynastix/files/certificates/snx_2024_certificate.pdf',
		[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: null
	}
}


export interface ValidChildrenAndEvaluation {
	valid_children: Child[]
	evaluation: Evaluation
}

export interface CertificateDialogLocalstorageData {
    selected_country_term_id: number
    selected_term_index: number
    selected_certificate_type: CertificateNames
    selected_certifcate_send_method: CertifcateSendMethodType
    selected_back_date_certificate: string
}

export interface CertifcateChildren {
	intial_cleaned_children: Child[]
	participation_valid_children: Child[]
	valid_children_and_evaluations: ValidChildrenAndEvaluation[]
	invalid_evaluation: Child[]
	not_allocated_class: Child[]
	not_authorised_children: Child[]
	send_to_school_children: Child[]
	no_parent_email_or_phone_number: Child[]
	not_in_selected_term_children: Child[]
	incomplete_assessment_children: Child[]
	not_ready_to_send_certificate_children: Child[]
	participation_certificate_already_sent_children: Child[]
	evaluation_certificate_already_sent_children: Child[]
}


const CertificatesDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('children')

	const number_ids = ids.map(id => parseInt(id, 10))

	const {setMessage} = useContext(MessageContext)
	const {num_of_requests, setNumOfRequests} = useContext(NumOfRequestsContext)

	const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const [shift_up, setShiftUp] = useState('0')
	const [shift_down, setShiftDown] = useState('0')
	const [shift_right, setShiftRight] = useState('0')
	const [shift_left, setShiftLeft] = useState('0')

	const company = getLocalStorageCompany()

	const certificate_dialog: CertificateDialogLocalstorageData | null = localStorage.getItem('certificate_dialog') ? JSON.parse(localStorage.getItem('certificate_dialog') as string) : null

	const [certificate_type, setCertificateType] = useState<CertificateNames>(certificate_dialog ? certificate_dialog.selected_certificate_type : CertificateNames.STANDARD_CERTIFICATE)
    const [selected_certifcate_send_method, setSelectCertiifcateSendMethod] = useState<CertifcateSendMethodType>(certificate_dialog ? certificate_dialog.selected_certifcate_send_method : CertifcateSendMethodType.EMAIL)
	const [children, setChildren] = useState<CertifcateChildren>({
		intial_cleaned_children: [],
		participation_valid_children: [],
		valid_children_and_evaluations: [],
		invalid_evaluation:[],
		not_allocated_class: [],
		not_authorised_children: [],
		send_to_school_children: [],
		no_parent_email_or_phone_number: [],
		not_in_selected_term_children: [],
		incomplete_assessment_children: [],
		not_ready_to_send_certificate_children: [],
		participation_certificate_already_sent_children: [],
		evaluation_certificate_already_sent_children: []
	})
    const [country_term_id, setCountryTermId] = useState<number | 'All' | null>(certificate_dialog ? certificate_dialog.selected_country_term_id : null)
    const [term_index, setTermIndex] = useState<number | null>(certificate_dialog ? certificate_dialog.selected_term_index : null)
    const [back_date_certificate, setBackDateCertificate] = useState<Record<string, Dayjs | null>>({
		'back_date_certificate': certificate_dialog && certificate_dialog.selected_back_date_certificate ? dayjs(certificate_dialog.selected_back_date_certificate) : null,
	})

	const is_email_certificates_checked = selected_certifcate_send_method === CertifcateSendMethodType.EMAIL
	const is_print_certificates_checked = selected_certifcate_send_method === CertifcateSendMethodType.PRINT

	const getCertificateDoc = async (certificate_url_path: string): Promise<PDFDocument> => {
		const response = await fetch(certificate_url_path)
		const data = await response.arrayBuffer()
		const pdf_doc = await PDFDocument.load(data)

		return pdf_doc
	}

	const findValidChildren = async (country_term_id: number | 'All') => {
		const selected_children: Child[] = state_manager.data.filter((child: Child) => number_ids.includes(child.id))

		const {valid_term_children, not_in_selected_term_children} = await inInvalidTermChildren(selected_children, country_term_id)
		const {intial_cleaned_children, not_authorised_children, not_allocated_class, not_ready_to_send_certificate_children} = await cleanChildren(valid_term_children)
		
		setChildren(props => ({
			...props,
			intial_cleaned_children: intial_cleaned_children,
			not_authorised_children: not_authorised_children,
			not_allocated_class: not_allocated_class,
			not_in_selected_term_children: not_in_selected_term_children,
			not_ready_to_send_certificate_children: not_ready_to_send_certificate_children
		}))

		return intial_cleaned_children
	}

	const findValidEmailCertificateChildren = async (initial_children: Child[]) => {
		const {valid_children, send_to_school_children, no_parent_email_or_phone_number} = await cleanEmailMethodChildren(initial_children)
		
		setChildren(props => ({
			...props,
			send_to_school_children: send_to_school_children,
			no_parent_email_or_phone_number: no_parent_email_or_phone_number,
		}))

		return valid_children
	}

	const findVadlidParticipationChildren = async (initial_children: Child[]) => {
		const {valid_children, participation_certificate_already_sent_children} = await cleanParticipationChildren(initial_children, country_term_id, term_index)

		setChildren(props => ({
			...props,
			participation_valid_children: valid_children,
			participation_certificate_already_sent_children: participation_certificate_already_sent_children
		}))
	}

	const findValidEvaluationChildren = async (initial_children: Child[]) => {
		if (!initial_children.length) {
			return
		}

		if (!term_index) {
			setMessage(props => ({...props, error: "Invalid term selected. Select a term."}))
			return
		}

		const company_terms = company.terms.filter(term => country_term_id === term.country_term)
		const term: Term = company_terms[term_index - 1]

		const evaluation_actions = new EvaluationActions()
		const evaluations: Evaluation[] = await evaluation_actions.get(undefined, undefined, {children: initial_children.map(child => child.id), term: term.id})

		let cleaned_out_invalid_or_incomplete_eval_children: Child[] = []
		let valid_children_and_evaluations: ValidChildrenAndEvaluation[] = []
		let invalid_evaluation_childrens: Child[] = []
		let evaluation_certificate_already_sent_childrens: Child[] = []
		let incomplete_assessment_children: Child[] = []

		for (const child of initial_children) {
			const found_in_any_evaluation = evaluations.some(evaluation =>
				evaluation.children.some(searched_child => searched_child === child.id)
			)
		
			if (!found_in_any_evaluation) {
				log.warn(`Child ${child.username} (ID: ${child.id}) has no evaluations. Skipping certificate creation for this child...`)
				invalid_evaluation_childrens.push(child)
				continue
			}

			cleaned_out_invalid_or_incomplete_eval_children.push(child)
		}
			
		for (const evaluation of evaluations) {
			const children_for_this_evaluation = cleaned_out_invalid_or_incomplete_eval_children.filter(child => evaluation.children.includes(child.id))
			const {valid_children, evalaution_incomplete_assessment_children, evaluation_certificate_already_sent_children} = await cleanEvaluationChildren(children_for_this_evaluation, country_term_id, term_index)
			
			const needing_certificate_filterd_valid_children = valid_children.filter(child => !evaluation_certificate_already_sent_children.some(already_sent_child => already_sent_child.id === child.id))
			console.log(needing_certificate_filterd_valid_children)

			incomplete_assessment_children.push(...evalaution_incomplete_assessment_children)
			const valid_children_and_evaluation = {
				valid_children: needing_certificate_filterd_valid_children,
				evaluation: evaluation
			}
			valid_children_and_evaluations.push(valid_children_and_evaluation)
			evaluation_certificate_already_sent_childrens.push(...evaluation_certificate_already_sent_children)
		}

		setChildren(props => ({
			...props,
			valid_children_and_evaluations: valid_children_and_evaluations,
			invalid_evaluation: invalid_evaluation_childrens,
			incomplete_assessment_children: incomplete_assessment_children,
			evaluation_certificate_already_sent_children: evaluation_certificate_already_sent_childrens
		}))
	}

	useEffect(() => {
		if (!country_term_id)
			return

		localStorage.setItem('certificate_dialog', JSON.stringify({
			selected_country_term_id: country_term_id,
			selected_term_index: term_index,
			selected_certificate_type: certificate_type,
			selected_certifcate_send_method: selected_certifcate_send_method,
			selected_back_date_certificate: back_date_certificate['back_date_certificate']
		}))
	}, [country_term_id, term_index, certificate_type, selected_certifcate_send_method, back_date_certificate])
	
	useEffect(() => {
		setChildren({
			intial_cleaned_children: [],
			participation_valid_children: [],
			valid_children_and_evaluations: [],
			invalid_evaluation:[],
			not_allocated_class: [],
			not_authorised_children: [],
			send_to_school_children: [],
			no_parent_email_or_phone_number: [],
			not_in_selected_term_children: [],
			incomplete_assessment_children: [],
			not_ready_to_send_certificate_children: [],
			participation_certificate_already_sent_children: [],
			evaluation_certificate_already_sent_children: []
		})

		const runValidateChildren = async () => {
			if (!country_term_id)
				return

			let initial_children = await findValidChildren(country_term_id)

			if (is_email_certificates_checked)
				initial_children = await findValidEmailCertificateChildren(initial_children)

			if (certificate_type === CertificateNames.PERCEPTUAL_MA_CERTIFICATE)
				findValidEvaluationChildren(initial_children)
			else if (certificate_type === CertificateNames.STANDARD_CERTIFICATE)
				findVadlidParticipationChildren(initial_children)
		}

		runValidateChildren()

    }, [is_email_certificates_checked, term_index, selected_certifcate_send_method, certificate_type, country_term_id])

	async function processChild(child: Child, evaluation: Evaluation | null, certificate_type: CertificateNames, unwanted_keys_in_child_evaluation: ChildEvaluationKeys[], term_index: number, helvetica_font: any, text_config: TextConfig, date_on_certificate: string, children_pdf_docs: ChildrenPDFDocs[]) {
		let pdf_doc: PDFDocument
		let add_height = 0
		
		if (!child.school_type) {
			throw new Error("School type on child must exist")
		}
	
		let certificate_url_path
		if (is_print_certificates_checked) {
			certificate_url_path = "monkeynastix/files/certificates/blank_pdf_for_printing.pdf"
		} else {
			certificate_url_path = SCHOOL_TYPE_TO_CERTIFICATE_PATHS_MAP[child.school_type][certificate_type]
			if (!certificate_url_path) {
				setMessage(props => ({...props, warning: `No ${CertificateNamesToDisplayMap[certificate_type]} for ${capitalizeFirstLetter(child.school_type as string)}`}))
				throw new Error(`'checked' key, ${certificate_type}, doesn't match any valid certificate type.`)
			}
		}
	
		pdf_doc = await getCertificateDoc(certificate_url_path) // find the doc in the iNastix file structure
		helvetica_font = await pdf_doc.embedFont(StandardFonts.Helvetica)
		const pages = pdf_doc.getPages()
		const certificate_page: PDFPage = pages[0]
	
		if (child.school_type === SchoolType.SUPERNASTIX) {
			add_height += 21
		}
	
		await drawOnCertificate(
			child,
			certificate_type,
			certificate_page,
			date_on_certificate,
			unwanted_keys_in_child_evaluation,
			term_index,
			text_config,
			evaluation,
			child.instructor_name || null,
			add_height,
			undefined,
			is_print_certificates_checked,
			shift_up,
			shift_down,
			shift_left,
			shift_right
		)
	
		return updateChildPDFDocs(children_pdf_docs, child, pdf_doc, certificate_type)
	}

	const handleCreateCertifcateBlobs = async (): Promise<ChildrenPDFDocs[] | null> => {
		if (!term_index) {
			setMessage(props => ({...props, error: "Please select a term index"}))
			return null
		}

		let helvetica_font
		const text_config: TextConfig = {
			size: 20,
			font: helvetica_font,
			color: rgb(0, 0, 0),
		}

		let date_on_certificate: string
		if (back_date_certificate['back_date_certificate']) {
			const dayjs_date_on_certificate: Dayjs = back_date_certificate['back_date_certificate']
			date_on_certificate = dayjs_date_on_certificate.format('YYYY-MM-DD')
		}
		else
			date_on_certificate = new Date().toISOString().split('T')[0]

		let children_pdf_docs: ChildrenPDFDocs[] = []

		const unwanted_keys_in_child_evaluation: ChildEvaluationKeys[] = ['id', 'child', 'child_name', 'school_name', 'connected_company_name', 'company_class_name', 'evaluation']

		try {
			if (certificate_type === CertificateNames.PERCEPTUAL_MA_CERTIFICATE) {
				for (const child_and_evaluation of children.valid_children_and_evaluations) {
					const evaluation = child_and_evaluation.evaluation
			
					for (const child of child_and_evaluation.valid_children) {
						children_pdf_docs = await processChild(
							child,
							evaluation,
							certificate_type,
							unwanted_keys_in_child_evaluation,
							term_index,
							helvetica_font,
							text_config,
							date_on_certificate,
							children_pdf_docs
						)
					}
				}
			
				for (const child of children.participation_valid_children) {
					if (!child.instructor_name) {
						setMessage(props => ({ ...props, error: `${child.username} has no class. Allocate one to create certificate` }))
						continue
					}
			
					children_pdf_docs = await processChild(
						child,
						null,
						CertificateNames.STANDARD_CERTIFICATE,
						unwanted_keys_in_child_evaluation,
						term_index,
						helvetica_font,
						text_config,
						date_on_certificate,
						children_pdf_docs
					)
				}
			}
			else if (certificate_type === CertificateNames.STANDARD_CERTIFICATE) {
				for (const child of children.participation_valid_children) {
					if (!child.instructor_name) {
						setMessage(props => ({ ...props, error: `${child.username} has no class. Allocate one to create certificate` }))
						continue
					}
			
					children_pdf_docs = await processChild(
						child,
						null,
						certificate_type,
						unwanted_keys_in_child_evaluation,
						term_index,
						helvetica_font,
						text_config,
						date_on_certificate,
						children_pdf_docs
					)
				}
			}
				
		} catch (error) {
			log.error(error)
			return children_pdf_docs
	  	}

		try {
			if (!children_pdf_docs.length) {
				log.info("No certificates to send/print. No valid children selected.")
				setMessage(props => ({...props, info: t('no_certificates_to_send_print_no_valid_children_selected')}))
				return children_pdf_docs
			}
			return children_pdf_docs
		} catch (error) {
			log.error(error)
			return children_pdf_docs
	  	}
	}

	function getAllPDFDocs(childrenPDFDocsList: ChildrenPDFDocs[]): PDFDocument[] {
		const allPDFDocs: PDFDocument[] = []
	
		childrenPDFDocsList.forEach(child => {
			if (child.perceptual_ma_certificate) {
				allPDFDocs.push(child.perceptual_ma_certificate)
			}
			if (child.standard_certificate) {
				allPDFDocs.push(child.standard_certificate)
			}
		})
	
		return allPDFDocs
	}

	const getDetailsFrontCoverForPrinting = (children_pdf_docs: ChildrenPDFDocs[]): string => {
		return `
			<div style="padding: 1rem 2rem;">
				<div style="display: flex; justify-content: space-between; align-items: center">
					<h4 style="font-size: 1.5rem; margin-bottom: 2rem;">Printing Details - Front Cover</h4>
					<img src=${InastixWord} width="80ch" alt="iNastix logo"/>
				</div>
				<div style="overflow-x: auto;">
					<table style="width: 100%; border-collapse: collapse; min-width: 650px;">
						<thead>
							<tr>
								<th style="border: 2px solid gray; text-align: left; padding: 8px; font-size: 0.9rem;">Child Name</th>
								<th style="border: 2px solid gray; text-align: right; padding: 8px; font-size: 0.9rem;">Program Type</th>
								<th style="border: 2px solid gray; text-align: right; padding: 8px; font-size: 0.9rem;">School</th>
								<th style="border: 2px solid gray; text-align: right; padding: 8px; font-size: 0.9rem;">Class Name</th>
							</tr>
						</thead>
						<tbody>
							${children_pdf_docs.map(child_pdf_doc => `
								<tr>
									<td style="border: 1px solid gray; text-align: left; padding: 8px; font-size: 0.8rem;">${child_pdf_doc.child.username}</td>
									<td style="border: 1px solid gray; text-align: right; padding: 8px; font-size: 0.8rem;">${child_pdf_doc.child.school_type}</td>
									<td style="border: 1px solid gray; text-align: right; padding: 8px; font-size: 0.8rem;">${child_pdf_doc.child.school_name}</td>
									<td style="border: 1px solid gray; text-align: right; padding: 8px; font-size: 0.8rem;">${child_pdf_doc.child.class_name}</td>
								</tr>
							`).join('')}
						</tbody>
					</table>
				</div>
			</div>
		`;
	};

	const generatePrintingDetailsFrontCoverPDFDocument = async (children_pdf_docs: ChildrenPDFDocs[]): Promise<PDFDocument> => {
		const element = document.createElement('div')
		element.innerHTML = getDetailsFrontCoverForPrinting(children_pdf_docs)
		document.body.appendChild(element)

		const pdfBlob = await html2pdf()
			.from(element)
			.set({
				margin: 1,
				filename: 'Front Cover.pdf',
				html2canvas: { scale: 2 },
				jsPDF: { orientation: 'portrait' }
			})
			.outputPdf('blob')

		document.body.removeChild(element)

		// Load the PDF Blob into a PDFDocument instance
		const pdfArrayBuffer = await pdfBlob.arrayBuffer()
		const pdfDoc = await PDFDocument.load(pdfArrayBuffer)

		return pdfDoc
	}

	const handlePrintCertificates = async (children_pdf_docs: ChildrenPDFDocs[]): Promise<Child[]> => {
		const frontCoverPDFDoc = await generatePrintingDetailsFrontCoverPDFDocument(children_pdf_docs)

		const pdf_docs = getAllPDFDocs(children_pdf_docs)
		pdf_docs.unshift(frontCoverPDFDoc)

		const combined_blob = await combinePDFs(pdf_docs)

		const combined_url = URL.createObjectURL(combined_blob)

		const print_window = window.open(combined_url, '_blank')

		if (print_window)
			print_window.onload = () => print_window.print()			
		else {
			setMessage(props => ({...props, warning: t('cant_create_print_dialog_try_enabling_browser_popups')}))
			log.error("Could not open new window.")
		}

		const form_data = new FormData()

		let school_type: string
		let certificate_name: string

		const CERTIFICATE_NAME_MAP = {
			[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: "movement",
			[CertificateNames.STANDARD_CERTIFICATE]: "participation"
		}

		for (const children_pdf_doc of children_pdf_docs) {
			const child_id = children_pdf_doc.child.id
			school_type = capitalizeFirstLetter(children_pdf_doc.child.school_type as string)

			if (children_pdf_doc.standard_certificate) {
				certificate_name = CERTIFICATE_NAME_MAP[CertificateNames.STANDARD_CERTIFICATE]
				const blob = new Blob([new Uint8Array(await children_pdf_doc.standard_certificate.save())], { type: 'application/pdf' })
				form_data.append(`${certificate_name}:${child_id}-${children_pdf_doc.send_to_school}`, blob, `${school_type} ${capitalizeFirstLetter(certificate_name)} Certificate.pdf`)
			}

			if (children_pdf_doc.perceptual_ma_certificate) {
				certificate_name = CERTIFICATE_NAME_MAP[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]
				const blob = new Blob([new Uint8Array(await children_pdf_doc.perceptual_ma_certificate.save())], { type: 'application/pdf' })
				form_data.append(`${certificate_name}:${child_id}-${children_pdf_doc.send_to_school}`, blob, `${school_type} ${capitalizeFirstLetter(certificate_name)} Certificate.pdf`)
			}
		}

		if (country_term_id)
			form_data.append("selected_country_term_id", country_term_id.toString() as string | 'All')

		if (!term_index)
			return []

		form_data.append("selected_term_index", term_index.toString() as string)

		const generic_fetchwrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
		return generic_fetchwrapper.post(PRINT_CERTIFICATES_TO_MEMBERS, form_data)
		.then((children: Child[]) => {
			return children
		})
	}

	const handleSendCertificates = async (children_pdf_docs: ChildrenPDFDocs[]): Promise<Child[]> => {
		const form_data = new FormData()

		let school_type: string
		let certificate_name: string

		const CERTIFICATE_NAME_MAP  = {
			[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: "movement",
			[CertificateNames.STANDARD_CERTIFICATE]: "participation"
		}

		for (const children_pdf_doc of children_pdf_docs) {
			const child_id = children_pdf_doc.child.id
			school_type = capitalizeFirstLetter(children_pdf_doc.child.school_type as string)

			if (children_pdf_doc.standard_certificate) {
				certificate_name = CERTIFICATE_NAME_MAP[CertificateNames.STANDARD_CERTIFICATE]
				const blob = new Blob([new Uint8Array(await children_pdf_doc.standard_certificate.save())], { type: 'application/pdf' })
				form_data.append(`${certificate_name}:${child_id}-${children_pdf_doc.send_to_school}`, blob, `${school_type} ${capitalizeFirstLetter(certificate_name)} Certificate.pdf`)
			}

			if (children_pdf_doc.perceptual_ma_certificate) {
				certificate_name = CERTIFICATE_NAME_MAP[CertificateNames.PERCEPTUAL_MA_CERTIFICATE]
				const blob = new Blob([new Uint8Array(await children_pdf_doc.perceptual_ma_certificate.save())], { type: 'application/pdf' })
				form_data.append(`${certificate_name}:${child_id}-${children_pdf_doc.send_to_school}`, blob, `${school_type} ${capitalizeFirstLetter(certificate_name)} Certificate.pdf`)
			}
		}

		if (country_term_id)
			form_data.append("selected_country_term_id", country_term_id.toString() as string | 'All')

		if (!term_index)
			return []

		form_data.append("selected_term_index", term_index.toString() as string)

		const generic_fetchwrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
		return generic_fetchwrapper.post(SEND_CERTIFICATES_TO_MEMBERS, form_data)
		.then((children: Child[]) => {
			return children
		})
	}

	const handleCertificatesSubmit = async () => {
		setNumOfRequests(props => props + 1)

		const children_pdf_docs = await handleCreateCertifcateBlobs()
		if (!children_pdf_docs) {
			setNumOfRequests(0)
			return

		}

		if (!children_pdf_docs.length) {
			setMessage(props => ({...props, error: t('no_certifcates_created')}))
			setNumOfRequests(0)
			return
		}

		const sendCertificatesPromise = is_email_certificates_checked ? handleSendCertificates(children_pdf_docs) : null
		const printCertificatesPromise = is_print_certificates_checked ? handlePrintCertificates(children_pdf_docs) : null
	
		Promise.all([sendCertificatesPromise, printCertificatesPromise])
		.then(([sent_children, printed_children]) => {
			const combined_array = [...(sent_children || []), ...(printed_children || [])]

			let message = ""
			if (is_email_certificates_checked && is_print_certificates_checked)
				message = t('certificates_sent_certificates_ready_to_print')
			else if (is_email_certificates_checked)
				message = t('certificates_sent')
			else if (is_print_certificates_checked)
				message = t('certificates_ready_to_print')
			// Since either handleSendCertificates or handlePrintCertificates will always be called,
			// it is impossible to reach this else statement.
			// Therefore, it is intentionally left out for clarity.

			state_manager.setUpdateMultipleAction(combined_array)

			setMessage(props => ({...props, success: message}))
			handleClose()
		})
		.catch(() => {
			setMessage(props => ({...props, message: "Something went wrong. Please try again with fewer selected children."}))
		})
		.finally(() => {
			setNumOfRequests(0)
		})
	}

	const handleChange = (event: SelectChangeEvent) => {
		setCertificateType(event.target.value as CertificateNames)
	}

	const handleClose = () => {
		onClose()
	}

	const handleShiftUpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShiftUp(event.target.value)
	}

	const handleShiftDownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShiftDown(event.target.value)
	}

	const handleShiftLeftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShiftLeft(event.target.value)
	}

	const handleShiftRightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShiftRight(event.target.value)
	}

	const getButtonString = () => {
		if (is_email_certificates_checked && is_print_certificates_checked)
			return t('print_send_certificates')
		else if (is_email_certificates_checked)
			return t('send_certificates')
		else if (is_print_certificates_checked)
			return t('print')
		else
			return t('done')
	}
	console.log(children)

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('member_certificates')} </INastixDialogTitle>
			<DialogDivider/>
			<DialogFormControl>
			<ValidateChildrenForPrint certifcate_children={children} setChildren={setChildren}/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box display='flex' gap={'2rem'}>
						<SelectCountryTermsOnly country_term_id={country_term_id} setCountryTermId={setCountryTermId}/>
						<SelectTermIndex country_term_id={country_term_id} term_index={term_index} setTermIndex={setTermIndex}/>
					</Box>
					<FormGroup>
						<br/>
						<FormControl fullWidth>
							<InputLabel id="certificate-type-select-label">Certificate type</InputLabel>
							<Select
								labelId="certificate-type-select-label"
								id="certificate-type-select"
								value={certificate_type}
								label="Certificate Type"
								onChange={handleChange}
							>
								<MenuItem value={CertificateNames.STANDARD_CERTIFICATE}>{t('participation_certificate')}</MenuItem>
								<MenuItem value={CertificateNames.PERCEPTUAL_MA_CERTIFICATE}>{t('perceptual_ma_certificate')}</MenuItem>
							</Select>
						</FormControl>
						<br/>
						<SelectCertificateSendMethod selected_certifcate_send_method={selected_certifcate_send_method} setSelectCertiifcateSendMethod={setSelectCertiifcateSendMethod}/>
						<br/>
						<DatePickerObjectNastix
							disabled={false}
							dates={back_date_certificate} 
							setDates={setBackDateCertificate}
							dates_key='back_date_certificate'
							label={"Back date certificate"}
						/>
					</FormGroup>
                </Grid>
				{ selected_certifcate_send_method === 'print' && <>
					<br/>
					<Grid item md={6}>
						<TextField 
							id="shift-up"
							label={t('shift_up')} 
							name="shift_up"
							variant="outlined"
							type="number"
							value={shift_up}
							onChange={handleShiftUpChange}
							sx={{marginBottom: 2}}
							fullWidth
						/>
						<TextField 
							id="shift-down"
							label={t('shift_down')} 
							name="shift_down"
							variant="outlined"
							type="number"
							value={shift_down}
							onChange={handleShiftDownChange}
							sx={{marginBottom: 2}}
							fullWidth
						/>
					</Grid>
					<Grid item md={6}>
						<TextField 
							id="shift-left"
							label={t('shift_left')} 
							name="shift_left"
							variant="outlined"
							type="number"
							value={shift_left}
							onChange={handleShiftLeftChange}
							sx={{marginBottom: 2}}
							fullWidth
						/>
						<TextField 
							id="shift-right"
							label={t('shift_right')} 
							name="shift_right"
							variant="outlined"
							type="number"
							value={shift_right}
							onChange={handleShiftRightChange}
							sx={{marginBottom: 2}}
							fullWidth
						/>
					</Grid>
				</> }
			</Grid>
			</DialogFormControl>
			<Button disabled={num_of_requests > 0} onClick={handleCertificatesSubmit} variant="contained" sx={{width: '100%'}}>
				{getButtonString()}
			</Button>
		</INastixDialog>
	)
}

async function combinePDFs(pdf_docs: PDFDocument[]) {
    const combined_pdf_doc = await PDFDocument.create()

    for (const pdf_doc of pdf_docs) {
        const pages = await combined_pdf_doc.copyPages(pdf_doc, pdf_doc.getPageIndices())
        pages.forEach(page => combined_pdf_doc.addPage(page))
    }

    const combined_data = await combined_pdf_doc.save()
    return new Blob([combined_data], { type: 'application/pdf' })
}


const MemberCertificatesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('children')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> {t('member_certificates')} </Typography>
		</TableActionButon>
		{open && <CertificatesDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default MemberCertificatesDialogHandler