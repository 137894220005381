import SchoolActions from "@actions/CRUDActions/SchoolActions/schoolActions"
import INastixTabs from "@components/navigation/INastixTabs"
import SchoolTable from "@components/tables/CRUD/SchoolTable"
import OldSchoolTable from "@components/tables/CRUD/SchoolTable/OldSchoolTable/OldSchoolTable"
import { ChangeCompanyRerenderContext } from "@contexts/TriggerRerender/companyRerender"
import { getLocalStorageCompany } from "@utils/localStorage/company"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"


const SchoolsTabsMap = {
    'previous_schools': 1
}

const SchoolsTabs = () => {
    const { t } = useTranslation('schools')

    const school_actions = useMemo(() => new SchoolActions(), [])

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

    const connected_company = getLocalStorageCompany()

    let nav_items = [t('schools')]

    if (connected_company.is_transferring_data)
        nav_items.push(t('previous_schools'))

    let tab_items = [<SchoolTable action={school_actions}/>]

    if (connected_company.is_transferring_data)
        tab_items.push(<OldSchoolTable action={school_actions}/>)

    return (
        <INastixTabs
            nav_items={nav_items}
            tab_items={tab_items}
            tabs_map={SchoolsTabsMap}
            tabs_key={tab_key}
        />
    )
}

export default SchoolsTabs