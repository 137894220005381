import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Theme, Typography, useMediaQuery} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import DateRangePickerNastix from '@components/pickers/DateRangeNastix'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { downloadFile } from '@utils/files/blobs'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'


const CreateMasterFranchisePackageReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('t_shirt_reports')

	const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const handlePackageSlipReportCreate = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		event.stopPropagation()
	
		setNumOfRequests(props => props + 1)
	
		const form_data = new FormData(event.currentTarget) 
		const form_data_object = Object.fromEntries(form_data.entries())
	
		const excel_report_actions = new ExcelReportActions()
	
		const data = {
			...form_data_object,
			franchise_ids: ids
		}
	
		try {
			const response = await excel_report_actions.createPackageSlip(data)
			console.log(response)
			
			setNumOfRequests(props => props - 1)
	
			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Package Slip Report ${current_date}.xlsx`
			downloadFile(response, filename)

		} catch (error) {
			console.error('Error downloading file:', error)
			setNumOfRequests(props => props - 1)
		}
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Package Slip </INastixDialogTitle>
			<DialogDivider/>
			<form onSubmit={handlePackageSlipReportCreate}>
				<DateRangePickerNastix
					label_range_1={t('start_date')}
					label_range_2={t('end_date')}
					name_range_1={'start_date'}
					name_range_2={'end_date'}
				/>
				<Button variant='contained' type="submit" sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        	</form>
        </INastixDialog>
	)
}


const CreateMasterFranchisePackageReporDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('t_shirt_reports')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Package Slip </Typography>
		</TableActionButon>
		<CreateMasterFranchisePackageReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateMasterFranchisePackageReporDialogHandler