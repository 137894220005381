import { useTranslation } from 'react-i18next'

import Table from '@components/tables/Table/Table'
import ChildPaymentsActions from '@actions/Finance/PaymentActions/childPaymentActions'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import ChildYearlyAuthorisationHandler from './childYearlyAuthorisationHandler'

export interface TableProps {
	action: ChildPaymentsActions
}

export const paymentsTableValueGetter = (params: any) => {
	if (params.field === 'child_start_date') {
		const datePart = new Date(params.value).toISOString().split('T')[0]
		return datePart
	}
}

const ChildYearlyTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('payments')

    const columns = [
        { field: 'id', headerName: t('id') },
        { field: 'child_name', headerName: t('name'), minWidth: 150, flex: 0.2 },
        { field: 'school_name', headerName: t('school'), minWidth: 150, flex: 0.2 },
        { field: 'child_start_date', sortable: false, filterable: false, headerName: t('start_date'), minWidth: 120, flex: 0.2, valueGetter: paymentsTableValueGetter},
        { field: 'yearly', headerName: t('yearly'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else if (params.value === false)
					return <Cancel sx={{ color: '#ed586e' }} />
                else
                    return ''
            },
        },
    ]

    return (
        <Table
            dialogs={[ChildYearlyAuthorisationHandler]}
            action={action}
            filter={{'payment_structure': 'yearly'}}
            columns={columns}
            page_size={100}
			amount_of_rows_selection={[100]}
            sort_by_asc='child_name'
        />
    )
}

export default ChildYearlyTable