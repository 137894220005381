import { useTranslation } from 'react-i18next'

import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import SchoolTermlyAuthorisationHandler from './schoolTermlyAuthorisationHandler'
import SchoolPaymentsActions from '@actions/Finance/PaymentActions/schoolPaymentActions'

export interface TableProps {
	action: SchoolPaymentsActions
}

const SchoolTermlyTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('payments')

    const user = getLocalStorageUser()

	const can_edit = permissionValid(user, UserPermissions.EDIT_CHILDREN)

    const columns = [
        { field: 'id', headerName: t('id') },
        { field: 'school_name', headerName: t('school'), minWidth: 150, flex: 0.2 },
        { field: 'num_children', headerName: t('num_children'), minWidth: 100, flex: 0.2 },
        { field: 'term_1', headerName: t('term_1'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
                else if (params.value === false)
					return <Cancel sx={{ color: '#ed586e' }} />
                else
                    return ''
            },
        },
        { field: 'term_2', headerName: t('term_2'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
                else if (params.value === false)
					return <Cancel sx={{ color: '#ed586e' }} />
                else
                    return ''
            },
        },
        { field: 'term_3', headerName: t('term_3'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
                else if (params.value === false)
					return <Cancel sx={{ color: '#ed586e' }} />
                else
                    return ''
            },
        },
        { field: 'term_4', headerName: t('term_4'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else
					return <Cancel sx={{ color: '#ed586e' }} />
            },
        },
    ]

    return (
        <Table
            single_selection_dialogs={[SchoolTermlyAuthorisationHandler]}
            action={action}
            filter={{'payment_structure': 'termly'}}
            columns={columns}
            page_size={100}
            amount_of_rows_selection={[100]}
            sort_by_asc='school_name'
        />
    )
}

export default SchoolTermlyTable