import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Theme, Typography, useMediaQuery} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import DateRangePickerNastix from '@components/pickers/DateRangeNastix'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'

import { handleTShirtReportCreate } from '../handleSend'
import { ReportDocType, TShirtReportType } from '../enum'
import { SelectReportDocType } from '../components'


const CreateMasterFranchiseTShirtReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('t_shirt_reports')

	const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [report_doc_type, setReportDocType] = useState<ReportDocType>(ReportDocType.EXCEL)

	const setReportDocTypeCallback = (value: ReportDocType) => {
		setReportDocType(value)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('create_t_shirt_report')} </INastixDialogTitle>
			<DialogDivider/>
			<form onSubmit={(event) => handleTShirtReportCreate(event, setNumOfRequests, 'company_uuids', ids, TShirtReportType.PER_FRANCHISE_REPORT, report_doc_type)}>
				<SelectReportDocType setReportDocTypeCallback={setReportDocTypeCallback}/>
				<DateRangePickerNastix
					label_range_1={t('start_date')}
					label_range_2={t('end_date')}
					name_range_1={'start_date'}
					name_range_2={'end_date'}
				/>
				<Button variant='contained' type="submit" sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        	</form>
        </INastixDialog>
	)
}


const CreateMasterFranchiseTShirtReporDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('t_shirt_reports')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> {t('create_t_shirt_report')} </Typography>
		</TableActionButon>
		<CreateMasterFranchiseTShirtReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateMasterFranchiseTShirtReporDialogHandler