import CompanyActions from "@actions/CompanyActions/companyActions"
import SchoolActions from "@actions/CRUDActions/SchoolActions/schoolActions"
import ChildPaymentsActions from "@actions/Finance/PaymentActions/childPaymentActions"
import SchoolPaymentsActions from "@actions/Finance/PaymentActions/schoolPaymentActions"
import INastixTabs from "@components/navigation/INastixTabs"
import ChildHalfTermlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildHalfTermlyTable"
import ChildMonthlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildMonthlyTable"
import ChildTermlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildTermlyTable"
import ChildYearlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildYearlyTable"
import SchoolMonthlyTable from "@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable/SchoolMonthlyTable"
import SchoolTermlyTable from "@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable/SchoolTermlyTable"
import SchoolYearlyTable from "@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable/SchoolYearlyTable"
import SchoolTable from "@components/tables/CRUD/SchoolTable"
import OldSchoolTable from "@components/tables/CRUD/SchoolTable/OldSchoolTable/OldSchoolTable"
import { ChangeCompanyRerenderContext } from "@contexts/TriggerRerender/companyRerender"
import { getLocalStorageCompany } from "@utils/localStorage/company"
import { ReactElement, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"


// const AuthorisationsTabsMap = {
//     'termly_members': 1,
//     'half_termly_members': 2,
//     'monthly_members': 3,
//     'per_class_members': 4,
//     'yearly_schools': 5,
//     'termly_schools': 6,
//     'monthly_schools': 7
// }

const AuthorisationsTabs = () => {
    const { t } = useTranslation('payments')

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const company = getLocalStorageCompany()

    const child_payment_actions = new ChildPaymentsActions()
    const school_payment_actions = new SchoolPaymentsActions()

    const [tabs, setTabs] = useState<ReactElement[]>([])
	const [nav_items, setNavItems] = useState<string[]>([])
    const [authorisations_tabs_map, setAuthorisationsTabsMap] = useState({})

    useEffect(() => {
        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            const finance = data.finance
            let all_tabs: ReactElement[] = []
            let all_navs: string[] = []

            let count = 0
            let tabs_map = {}

            if(finance.allows_yearly_fees === true) {
                all_tabs = [...all_tabs, 
                    <ChildYearlyTable action={child_payment_actions}/>, 
                    <SchoolYearlyTable action={school_payment_actions}/>
                ]
                all_navs = [...all_navs, t('yearly_members'), t('yearly_schools')]
                tabs_map = {"yearly_schools": count + 1}
                count = count + 2
            }
            if(finance.allows_termly_fees === true) {
                all_tabs = [...all_tabs,
                    <ChildTermlyTable action={child_payment_actions}/>,
                    <SchoolTermlyTable action={school_payment_actions}/>,
                    <ChildHalfTermlyTable action={child_payment_actions}/>,
                ]
                all_navs = [...all_navs, t('termly_members'), t('termly_schools'), t('half_termly_members')]
                if (count == 0)
                    tabs_map = {
                        "termly_schools": count + 1,
                        "half_termly_members": count + 2,
                    }
                else
                    tabs_map = {
                        "termly_members": count,
                        "termly_schools": count + 1,
                        "half_termly_members": count + 2,
                    }

                count = count + 3
            }
            if(finance.allows_monthly_fees === true) {
                all_tabs = [...all_tabs,
                    <ChildMonthlyTable action={child_payment_actions}/>,
                    <SchoolMonthlyTable action={school_payment_actions}/>
                ]
                all_navs = [...all_navs, t('monthly_members'), t('monthly_schools')]

                if (count == 0)
                    tabs_map = {
                        "monthly_schools": count + 1
                    }
                else
                    tabs_map = {
                        "monthly_members": count,
                        "monthly_schools": count + 1
                }
                count = count + 2
            }

            // PER CLASS MEMBERS

            setTabs(all_tabs)
            setNavItems(all_navs)
            setAuthorisationsTabsMap(tabs_map)
        })
    }, [change_company_rerender])

    const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

    return (
        <INastixTabs
            nav_items={nav_items}
            tab_items={tabs}
            tabs_map={authorisations_tabs_map}
            tabs_key={tab_key}
        />
    )
}

export default AuthorisationsTabs